<template>
    <div class="UserGroups full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <div class="content-area pa-8 pt-8 flex-grow-1 bg-white ">
                <v-data-table
                    disable-pagination
                    hide-default-footer
                    :headers="groupsTableHeaders"
                    :items="grouplist"
                >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item) in items" :key="item.gid" @click="editRow(item)">
                            <td>{{ item.name }}</td>
                            <td>{{ findStatus(item.status) }}</td>
                            <td :class="{edit: item.editable && checkUserRights('userManagementEdit'), show: !item.editable || !checkUserRights('userManagementEdit')}"></td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToUserManagement"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="printAll" :successCheck="showPrintSuccess" :progress="showPrintProgress" @footerButtonClick="printAll" :class="{'disabled': !checkUserRights('userManagementPrint')}"></footer-button>
            <footer-button buttonFunction="new" @footerButtonClick="newGroup" :class="{'disabled': !checkUserRights('userManagementEdit')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";

export default {
    name: 'UserGroups',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return {
            showPrintProgress: false,
            showPrintSuccess: false,
        }
    },
    computed: {
        ...mapState([
            'grouplist',
        ]),
        groupsTableHeaders(){
            return [
                { text: this.$t('general.name'), align: 'start', value: 'name' },
                { text: this.$t('general.status'), value: 'status' },
                { text:"", sortable: false,}
            ]
        },
    },
    methods: {
        findStatus(statusValue){
            let status = this.$t('general.active')
            if (statusValue === false){
                status = this.$t('general.inactive')
            }
            return status;
        },
        editRow(group){
            this.$store.commit('ChangeEditGroup', group);
        },
        goToUserManagement(){
            this.$router.push('user-management');
        },
        newGroup(){
            let group = {
                id: null,
                gid: '',
                name: '',
                loginAttempts: 0,
                autoLogoffStatus: false,
                autoLogoffTime: 0,
                complexPassword: false,
                groupPermissions: [],
                editable: true,
                status: true
            }
            this.$store.commit('ChangeEditGroup', group);
        },
        printAll(){
            this.showPrintProgress = true;
            this.$store.dispatch('getAxiosNoSetter', '/admin/group/printAll')
            .then(response =>{
                this.showPrintProgress = false;
                if (response.status === 200){
                    this.showPrintSuccess = true;
                    setTimeout(()=>{
                        this.showPrintSuccess = false;
                    }, 800)
                }
            });
        },
    },
    mixins: [mixins],
    created() {
        this.$store.dispatch('getAxiosSetter', ['/admin/group/list', 'grouplist']);
    },
}
</script>
<style scoped lang="scss">
</style>